import { InputProps as AntdInputProps } from "antd/lib/input"
import { TextAreaProps as AntdTextAreaProps } from "antd/lib/input/TextArea"
import { MaskedInputProps as AntdMaskedInputProps } from "antd-mask-input/build/main/lib/MaskedInput"
import { InputNumberProps as AntdInputNumberProps } from "antd/lib"
import { InputRef } from "antd"
import { Ref } from "react"

export interface InputProps extends AntdInputProps {
  placeholder?: string
  error?: boolean | string
  errors?: string[]
  rest?: any
  view?: boolean
  isMiddle?: boolean
  greyBackground?: boolean
  formatter?: (value: string | number) => string
  hideControls?: boolean
  disableScrollEvent?: boolean
  constantErrorHeight?: number
  ref?: Ref<InputRef>
}

export interface CurrencyInputProps extends InputProps {
  setValue: (value?: string | number) => void
  widthFromPrefix?: boolean
}

export interface TextAreaProps extends AntdTextAreaProps {
  error?: boolean | string
  greyBackground?: boolean
}

export interface UseGreyInputBackground {
  greyBackground?: boolean
  value?: string | number | readonly string[]
  allowNullValue?: boolean
}

export interface MaskedInputProps extends AntdMaskedInputProps {
  greyBackground?: boolean
}

export type InputNumberProps = AntdInputNumberProps

type AntdInputNumberClearProps = {
  suffix: AntdInputProps["suffix"]
}

export type InputNumberClearProps = AntdInputNumberClearProps & InputNumberProps

export interface ValidationResult {
  isValid: boolean
  isPotential: boolean
}

export enum InputType {
  min = "min",
  max = "max"
}
