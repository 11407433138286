import React, { useMemo } from "react"

import { useSelector } from "react-redux"

import { getAccessList } from "store/access/reducer"
import { MenuItemsAccess } from "store/access/types"

import Tree from "Components/Tree"

import { menuConstants } from "./constants"
import { CrudAccessMenuProps, TreeOptionProps } from "./types"
import { AccessTemplates } from "../types"

const CrudAccessMenu = ({
  requiredList,
  disabledAll,
  checked,
  onChange,
  templateName
}: CrudAccessMenuProps) => {
  const { menu_items } = useSelector(getAccessList)

  const { templates } = useSelector(getAccessList)

  const currentTemplate = templates.find(item => item.key === templateName)

  const menuForTree = useMemo(() => {
    const allDataArr = menu_items

    const itemToOption = (item: MenuItemsAccess[]): TreeOptionProps[] => {
      return item.map(option => {
        const isDisabled = requiredList.includes(option.name) || disabledAll
        return {
          title: option.label,
          key: option.name,
          disabled: isDisabled,
          children: !!option.inside ? itemToOption(option.inside) : []
        }
      })
    }
    const options = itemToOption(allDataArr)

    // Вычисляет максимальную вложенность меню
    const countNestDepth = (children: TreeOptionProps[]): number => {
      return children.reduce((depth, child) => {
        return Math.max(depth, 1 + countNestDepth(child.children))
      }, 0)
    }

    const filterOpt = (options: TreeOptionProps[]): TreeOptionProps[] => {
      return options.reduce((accum: TreeOptionProps[], obj) => {
        if (obj.children.length !== 0) {
          return [...accum, { ...obj, children: filterOpt(obj.children) }]
        } else if (currentTemplate?.menu_items.includes(obj.key)) {
          return [...accum, obj]
        }
        return accum
      }, [])
    }

    if (templateName === AccessTemplates.cashier) {
      const maxMenuDepth = countNestDepth(options)

      let optionsOpt = [...options]

      for (let i = 1; i <= maxMenuDepth; i++) {
        optionsOpt = filterOpt(optionsOpt)
      }
      return optionsOpt
    }
    return options
  }, [menu_items, requiredList, disabledAll])
  return (
    <div>
      <div className="SubtitleFirstText MarginBottom6">
        {menuConstants.label}
      </div>
      <div className="CrudAccessMenuWrapper Padding16">
        {!!menuForTree && (
          <Tree
            treeData={menuForTree}
            checkedKeys={checked}
            setCheckedKeys={onChange}
          />
        )}
      </div>
    </div>
  )
}

export default CrudAccessMenu
