export enum AccessTemplates {
  manager = `manager`,
  admin = `admin`,
  cashier = `cashier`,
  custom = `custom`,
  by_request = `by_request`,
  partner = `partner`,
  ldap = `ldap`
}

export interface MenuItemsAccess {
  name: string
  required: boolean
  label: string
  inside?: MenuItemsAccess[]
}

export interface MenuAccessList {
  menu_items: MenuItemsAccess[]
}

export enum DashboardsKeys {
  attendance = `attendance`,
  balance = `balance`,
  new_guests = `new_guests`,
  payments = `payments`,
  superwins = `superwins`
}

export interface DashboardList {
  dashboards: DashboardsKeys[]
}

export interface TemplatesItem extends DashboardList {
  key: AccessTemplates
  name: string
  menu_items_required: string[]
  menu_items: string[]
}

export interface TemplatesList extends DashboardList, MenuAccessList {
  templates: TemplatesItem[]
  custom_template_names: string[]
}
