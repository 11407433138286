import React from "react"

import { AccessTemplates, DashboardsKeys } from "store/access/types"

import superwinIcon from "./icons/superwin.png"
import balanceIcon from "./icons/balance.png"
import newRegistrationsIcon from "./icons/new_registrations.png"
import cashboxIcon from "./icons/cashbox.png"
import visitIcon from "./icons/visit_count.png"

import { ReactComponent as CloseIcon } from "Components/icons/close_round_20.svg"

import { FormProps, FormPropsData } from "./types"
import { CardsListData } from "../Cards/types"
import { AccessStatuses } from "../types"
import { isInteger } from "lodash"

export const labelText = {
  positionManageUrl: `v1/access/employee-position`,
  positionsListUrl: `v1/access/employee-positions`,
  full_name: {
    label: `ФИО`,
    field: `full_name`,
    placeholder: `Введите ФИО`
  },
  name: {
    field: `name`,
    placeholder: `Введите имя`
  },
  surname: {
    field: `surname`,
    placeholder: `Введите фамилию`
  },
  patronymic: {
    field: `patronymic`,
    placeholder: `Введите отчество`
  },
  unemployed: {
    label: `Нетрудоустроенный`
  },
  snils: {
    label: `СНИЛС`,
    field: `snils`,
    placeholder: `Введите СНИЛС`,
    custom_rule: {
      validator: <T, D>(_: T, value: string) => {
        value = value.replace(/[^0-9]+/g, ``)

        if (!!value) {
          if (!value && !isInteger(value)) {
            return Promise.reject(new Error(`Значение СНИЛС, 11 цифр`))
          } else if (`${value}`.length < 11) {
            return Promise.reject(new Error(`Значение СНИЛС, 11 цифр`))
          } else {
            return Promise.resolve()
          }
        } else {
          return Promise.resolve()
        }
      }
    }
  },
  login: {
    label: `Логин`,
    field: `login`,
    placeholder: `Введите логин`,
    custom_rule: {
      validator: <T, D>(_: T, value: D) => {
        if (!!value && `${value}`?.length < 6) {
          return Promise.reject(
            new Error(`Минимальное значение логина, 6 символов`)
          )
        } else {
          return Promise.resolve()
        }
      }
    }
  },
  template_name: {
    label: `Шаблон`,
    field: `template_name`,
    placeholder: `Выберите шаблон`
  },
  position: {
    label: `Должность`,
    field: `position`,
    placeholder: `Выберите из списка или создайте`,
    createBtn: `Создать`,
    initPosition: {
      label: (
        <div className={"AccessDividerWrapper"}>
          <span className={"AccessDividerInner"}>Стандартные</span>
        </div>
      ),
      options: []
    }
  },
  dashboards: {
    label: `Дашборды`,
    tooltip: `Дашборды, которые будут видны на главной странице, если в доступных пунктах меню был выбран раздел Главная. Можно выбрать все, несколько или ни одного дашборда.`,
    field: `dashboards`,
    placeholder: `Выберите дашборды`
  },
  halls: {
    field: `halls`,
    label: `Доступ к клубам`,
    placeholder: `Выберите клубы`
  },
  status: {
    label: `Статус доступа`,
    tooltip: `Возможны статусы Активен, Приостановлен и Возобновление. При создании нового доступа возможно выбрать только статусы Активен и Приостановлен, если доступ в статусе Приостановлен, возможно выбрать только статус Возобновление. В статусе Возобновление можно выбрать только статус Приостановлен.`,
    field: `status`,
    placeholder: `Выберите статус`
  },
  phone: {
    label: `Номер телефона`,
    tooltip: ``,
    field: `phone`,
    placeholder: `Введите номер телефона`
  },
  email: {
    label: `Email`,
    field: `email`,
    placeholder: `Введите Email`
  },
  password: {
    label: `Пароль`,
    field: `password`,
    placeholder: `Введите пароль`,
    link: `Сгенерировать`
  },
  send_password: {
    label: `Отправить пароль на почту`,
    field: `send_password`
  },
  submit: {
    create: `Создать`,
    save: `Сохранить`,
    ok: `Понятно`
  },
  menu_items: {
    label: `Пункты меню`,
    placeholder: `Выберите пункты меню`,
    field: `menu_items`
  }
}

export const statusOptions = ({ current }: { current?: AccessStatuses }) => {
  const active = [
    {
      value: AccessStatuses.active,
      label: `Активен`
    }
  ]
  const resuming = [
    {
      value: AccessStatuses.resuming,
      label: `Возобновление`
    }
  ]
  const paused = [
    {
      value: AccessStatuses.paused,
      label: `Приостановлен`
    }
  ]
  if (!current) {
    return [...active, ...paused]
  } else if (current === AccessStatuses.active) {
    return [...active, ...paused]
  } else {
    return [...resuming, ...paused]
  }
}

export const initialRequiredList = [`home`, `messages`]

export const homeAccessTag = "home"

const splitFio = (fio: string | undefined) => {
  return fio ? fio.split(" ") : []
}

export const formatSnils = (snilsString: string): string => {
  if (!snilsString) return ``

  snilsString = snilsString.replace(/[^0-9]+/g, ``)
  const length = snilsString.length

  const firstTrio = snilsString.slice(0, 3)
  const secondTrio = snilsString.slice(3, 6)
  const thirdTrio = snilsString.slice(6, 9)
  const coda = snilsString.slice(9, 11)

  switch (length) {
    case 0:
      return ``

    case 1:
    case 2:
    case 3:
      return `${firstTrio}`

    case 4:
    case 5:
    case 6:
      return `${firstTrio}-${secondTrio}`

    case 7:
    case 8:
    case 9:
      return `${firstTrio}-${secondTrio}-${thirdTrio}`

    case 10:
    case 11:
      return `${firstTrio}-${secondTrio}-${thirdTrio} ${coda}`

    default:
      return `${firstTrio}-${secondTrio}-${thirdTrio} ${coda}`
  }
}

export const initialForm = (data?: CardsListData): FormPropsData => {
  const splittedFio = splitFio(data?.full_name)
  return {
    full_name: data?.full_name || undefined,
    surname: splittedFio[0] || undefined,
    name: splittedFio[1] || undefined,
    patronymic: splittedFio[2] || undefined,
    snils: data?.snils || undefined,
    position: data?.position || undefined,
    employed: data?.employed || undefined,
    can_edit: data?.can_edit || undefined,
    login: data?.login || undefined,
    template_name: data?.template_name || undefined,
    send_to_email: false,
    halls: data?.halls || undefined,
    dashboards: data?.dashboards || undefined,
    status: data?.status || undefined,
    phone: data?.phone || undefined,
    email: data?.email || undefined,
    menu_items: data?.menu_items || initialRequiredList
  }
}

export const generatePassword = () => {
  const length = 11
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?"
  let result = ""
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

const dashboardOptions = [
  {
    value: DashboardsKeys.attendance,
    label: `Количество посещений`,
    icon: (
      <img
        src={visitIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  },
  {
    value: DashboardsKeys.payments,
    label: `Кассовый результат`,
    icon: (
      <img
        src={cashboxIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  },
  {
    value: DashboardsKeys.new_guests,
    label: `Новые регистрации`,
    icon: (
      <img
        src={newRegistrationsIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  },
  {
    value: DashboardsKeys.balance,
    label: `Баланс для выплат`,
    icon: (
      <img
        src={balanceIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  },
  {
    value: DashboardsKeys.superwins,
    label: `Супервыигрыш`,
    icon: (
      <img
        src={superwinIcon}
        alt="Загруженное изображение"
        className="AccessDashboardIcon"
      />
    )
  }
]

export const getDashboardOptions = (dashboards: DashboardsKeys[]) => {
  return dashboards.map(item => {
    return dashboardOptions.find(option => option.value === item)
  })
}

export const DashboardTagRender = (props: any, isDisabled: boolean) => {
  const { onClose, value } = props
  const currentDashboard = dashboardOptions.find(item => item.value === value)

  return (
    <div className="FlexRow Gap8 AccessDashboardTag">
      <div>{currentDashboard?.icon}</div>
      {!isDisabled && (
        <div className="HoverItem" onClick={onClose}>
          <CloseIcon />
        </div>
      )}
    </div>
  )
}

export const unionFio = (formState: FormProps) =>
  `${formState.data.surname} ${formState.data.name} ${formState.data
    .patronymic || ""}`.trim()

export const menuConstants = {
  label: `Доступные пункты меню:`,
  tags: `Добавленные пункты меню:`,
  title_create: `Создание доступа`,
  title_edit: `Редактирование доступа`,
  title_editPartner: `Подробная информация`
}

export const cancelModal = {
  title: `Продолжить редактирование?`,
  description: `Вы уверены что хотите выйти? Изменения не будут \nсохранены.`,
  cancel: `Выйти`,
  apply: `Продолжить`
}

export const stuffUrl = `v1/access/employees-without-access`
