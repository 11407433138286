import { getFilterStateFromLocalStorage } from "api/helpers"
import { isEmpty } from "lodash"
import { AccessFilters, AccessStatuses } from "./types"

export const HALL_ACCESS_FILTER = `HALL_ACCESS_FILTER`

export const StatusNames = {
  [AccessStatuses.active]: `Активен`,
  [AccessStatuses.paused]: `Приостановлен`,
  [AccessStatuses.resuming]: `Возобновление`
}

export const initialFilter = (current_halls: number[]): AccessFilters => {
  const initFilterStateFromStorage = getFilterStateFromLocalStorage(
    {},
    HALL_ACCESS_FILTER,
    true
  )

  const haveFilterFromStorage = !isEmpty(initFilterStateFromStorage)

  const defaultData = {
    halls: current_halls,
    search: undefined,
    status: 0
  }

  return {
    isOpen: true,
    mobileFilter: false,
    isActive: haveFilterFromStorage,
    data: haveFilterFromStorage ? initFilterStateFromStorage : defaultData
  }
}

export const accessBaseConstants = {
  title: `Доступ к кабинету`,
  tooltip: `Раздел позволяет просматривать учетные записи, имеющие доступ в личный кабинет и другие партнёрские сервисы по клубу или группе клубов. Создание новых и редактирование существующих доступов выполняет курирующий менеджер. Информация о трудоустроенных сотрудниках передается из программы 1C. Для таких сотрудников своевременно отслеживаются больничные, отпуска и увольнения. В зависимости от статуса сотрудника в программе 1C - доступ к ЛК может быть автоматически приостановлен или удален.`,
  addButton: `Добавить доступ`
}
