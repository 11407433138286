import moment from "moment"
import { isEmpty } from "lodash"
import { localStorageKeys } from "../Components/FilterInfoPanel/helpers"

export const getReportTableDateHeader = (period: string): string => {
  switch (period) {
    case `hourly`: {
      return `Время`
    }
    case `monthly`: {
      return `Месяц`
    }
    case `yearly`: {
      return `Год`
    }
    default:
      return `Дата`
  }
}

export const getFilterStateFromLocalStorage = (
  initState: any,
  filterName: string,
  datesAsObject?: true
): any => {
  const filterFromStorage = JSON.parse(localStorage.getItem(filterName) || "{}")
  if (!isEmpty(filterFromStorage)) {
    initState = {
      ...initState,
      ...filterFromStorage
    }
  }

  if (!isEmpty(filterFromStorage) && filterFromStorage.dates) {
    let nextDates = null

    if (datesAsObject) {
      nextDates = {
        from: moment(initState.dates.from),
        to: moment(initState.dates.to)
      }
    } else {
      nextDates = [
        filterFromStorage.dates[0]
          ? moment(filterFromStorage.dates[0])
          : moment(),
        filterFromStorage.dates[1]
          ? moment(filterFromStorage.dates[1])
          : moment()
      ]
    }

    initState = {
      ...initState,
      dates: nextDates
    }
  }
  return initState
}

export const saveFilterInLocalStorage = (
  filter: any,
  filterName: string
): void => {
  if (!!filter.dates && !!filter.dates.length) {
    const start = moment(filter.dates[0]).valueOf()
    const end = moment(filter.dates[1]).valueOf()
    localStorage.setItem(
      filterName,
      JSON.stringify({ ...filter, dates: [start, end] })
    )
  } else {
    localStorage.setItem(filterName, JSON.stringify({ ...filter }))
  }
}

export const locationsByGmt = (
  gmt: string
): { gmt: string; region: string; short: string } => {
  switch (gmt) {
    case `2`:
      return {
        gmt: gmt,
        region: `Калининград`,
        short: `Клг`
      }
    case `3`:
      return {
        gmt: gmt,
        region: `Москва`,
        short: `Мск`
      }
    case `4`:
      return {
        gmt: gmt,
        region: `Самара`,
        short: `Смр`
      }
    case `5`:
      return {
        gmt: gmt,
        region: `Екатеринбург`,
        short: `Екб`
      }
    case `6`:
      return {
        gmt: gmt,
        region: `Омск`,
        short: `Омс`
      }
    case `7`:
      return {
        gmt: gmt,
        region: `Новосибирск`,
        short: `Нов`
      }
    case `8`:
      return {
        gmt: gmt,
        region: `Иркутск`,
        short: `Ирк`
      }
    case `9`:
      return {
        gmt: gmt,
        region: `Якутск`,
        short: `Яку`
      }
    case `10`:
      return {
        gmt: gmt,
        region: `Владивосток`,
        short: `Влв`
      }
    case `11`:
      return {
        gmt: gmt,
        region: `Магадан`,
        short: `Маг`
      }
    case `12`:
      return {
        gmt: gmt,
        region: `Камчатский край`,
        short: `Птр`
      }
    default:
      return {
        gmt: gmt,
        region: `Не найден`,
        short: ``
      }
  }
}

export const gmtByHall = (hall: number) => {
  const hallAddressesStorage: string =
    localStorage.getItem(localStorageKeys.hallsAddress) || ``
  const hallAddresses: {
    [key: string]: { gmt: string; region: string }
  } = hallAddressesStorage ? JSON.parse(hallAddressesStorage) : {}
  const currentAddress = hallAddresses[hall]

  return currentAddress?.gmt
}

export const checkFileFormat = (name = ``) => {
  if (name.search(`.xlsx`) !== -1) return `xls`
  if (name.search(`.xls`) !== -1) return `xls`
  if (name.search(`.png`) !== -1) return `png`
  if (name.search(`.jpeg`) !== -1) return `jpg`
  if (name.search(`.jpg`) !== -1) return `jpg`
  if (name.search(`.docx`) !== -1) return `doc`
  if (name.search(`.doc`) !== -1) return `doc`
  if (name.search(`.pdf`) !== -1) return `pdf`
  if (name.search(`.pptx`) !== -1) return `pptx`
  return false
}
