import React, { useCallback, useMemo, useState } from "react"

import { noop } from "lodash"

import { useRequestParams } from "api/hooks"
import { isMobile, roleConstants } from "constants/index"

import ReportWithFilter from "Components/ReportWithFilter"
import { SideFilter } from "Components/SideFilter/SideFilter"
import { Filters } from "Components/SideFilter/types"
import { ButtonPrimary } from "Components/Button"
import { TitleTooltipComponent } from "Components/Title/Title"

import {
  accessBaseConstants,
  HALL_ACCESS_FILTER,
  initialFilter
} from "./constants"
import { AccessFilters } from "./types"

import AccessSearchFilter from "./CustomFilters/Search"
import AccessStatusFilter from "./CustomFilters/Status"
import AccessCards from "./Cards"
import { CardsListData } from "./Cards/types"

const AccessBase = ({
  openModal,
  refreshTrigger,
  refresh
}: {
  openModal: (data?: CardsListData) => void
  refreshTrigger: number
  refresh: () => void
}) => {
  const { current_hall_number } = useRequestParams()
  const initialFilterMemo: AccessFilters = useMemo(
    () => initialFilter(current_hall_number),
    []
  )
  const [filterState, setFilterState] = useState<AccessFilters>(
    initialFilterMemo
  )

  const setFilterStateCb = useCallback(value => {
    setFilterState(value)
  }, [])

  const { isPartner } = roleConstants()
  const viewMode = isPartner

  return (
    <>
      <ReportWithFilter
        filterComponent={
          <SideFilter
            filterName={HALL_ACCESS_FILTER}
            filterState={filterState}
            initialForClear={initialFilterMemo.data}
            filters={[
              Filters.customItem,
              Filters.hallPicker,
              Filters.addCustomItem
            ]}
            setFilterState={setFilterStateCb}
            resetPagination={noop}
            getDisabled={filter => !filter?.halls?.length}
            CustomComponent={AccessSearchFilter}
            AdditionalCustomComponent={AccessStatusFilter}
          />
        }
        mobileFilter={filterState.mobileFilter}
        isOpen={filterState.isOpen}
        toggleFilter={() =>
          setFilterState({ ...filterState, isOpen: !filterState.isOpen })
        }
        toggleMobileFilter={() =>
          setFilterState({
            ...filterState,
            mobileFilter: !filterState.mobileFilter
          })
        }
        pageContent={
          <div>
            <div
              className={`${
                isMobile ? `FlexColumn` : `FlexRow`
              } PaddingBottom24`}
            >
              <div className="FlexRow Gap4">
                <div className="AccessBaseTitle">
                  {accessBaseConstants.title}
                </div>
                <div className="MarginTop4">
                  <TitleTooltipComponent
                    tooltipText={accessBaseConstants.tooltip}
                  />
                </div>
              </div>
              {!viewMode && (
                <div className="AccessAddButton">
                  <ButtonPrimary isMiddle={true} onClick={() => openModal()}>
                    {accessBaseConstants.addButton}
                  </ButtonPrimary>
                </div>
              )}
            </div>
            <AccessCards
              filters={filterState.data}
              openModal={openModal}
              refresh={refresh}
              refreshTrigger={refreshTrigger}
            />
          </div>
        }
      />
    </>
  )
}

export default AccessBase
