import React from "react"

import EmptyTable from "Components/EmptyTable"
import { ReactComponent as NotFoundIcon } from "Components/icons/illustrations/not_found.svg"

import { CardsList } from "./types"
import { AccessStatuses } from "../types"

export const accessCardText = {
  url: {
    data: `v1/access`
  },
  card: {
    empty: `-`,
    login: `Логин:`,
    phone: `Телефон:`,
    created_date: `Дата создания:`,
    last_auth: `Последний вход:`,
    template_name: `Шаблон:`,
    position: `Должность:`,
    halls: `Доступные клубы:`,
    tooltipError: `Шаблон не соответствует должности`,
    status: {
      [AccessStatuses.active]: `Активен`,
      [AccessStatuses.paused]: `Приостановлен`,
      [AccessStatuses.resuming]: `Возобновление`
    },
    pause: {
      auto: `Автоматически`,
      manual: `Пользователем`
    },
    format: `DD.MM.YYYY`
  },
  emptyList: (
    <div className="AccessEmptyCarsList">
      <EmptyTable
        mobile={{
          title: `Здесь отображаются доступы`,
          subtitle: `Ничего не найдено`,
          icon: <NotFoundIcon />
        }}
        desktop={{
          title: `Здесь отображаются доступы`,
          subtitle: `Ничего не найдено`,
          icon: <NotFoundIcon />
        }}
      />
    </div>
  )
}

export const accessCardsInitialData: CardsList = {
  loading: false,
  data: [],
  total_count: 0
}

export const removeText = (name: string) => ({
  title: `Удалить доступ?`,
  content: `Вы уверены что хотите удалить доступ к кабинету для ${name} ?`,
  apply: `Да, удалить`,
  cancel: `Отмена`
})

export const translateAccessTemplate = (templateName: string | undefined) => {
  switch (templateName) {
    case "by_request":
      return "Доступ по запросу (ID)"
    case "ldap":
      return "Партнёр (ID)"
    default:
      return templateName
  }
}
